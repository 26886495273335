import { PublicConfig, PublicEnvironment } from '../../models/environment.public';
import { commonEnvVars, stagingEnv } from 'src/common/environments/environment.public.common';

const config: PublicConfig = {
    appId: 'l7xx895b1b4f07cf47d1ac5b0f80c7558959',
    appziSrc: `https://w.appzi.io/w.js?token=Abw7b`,
    brand: 'Airquest',
    brandPath: 'icp',
    publicBaseUrl: 'https://staging-airquest.hvacpartners.com',
    siteCode: 765,
    ...stagingEnv
};

export const environment: PublicEnvironment = { ...commonEnvVars(config) };
